import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';

const initialState = { userid: 0, orderid: "", orderdate: "", productdetails: [], firstname:"", lastname:"", mobile:"", address:"", pincode:"", totalqty:0, totalprice:0, totalamount:0 }
class Invoice extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentWillMount() {
		if (localStorage.getItem("webuser") != undefined) {
			const userID = JSON.parse(localStorage.getItem("webuser"));
			this.setState({ userid: userID.id })
		}
	}

	componentDidMount() {
		// console.log(this.props.match.params.slug)
		this.load(this.props.match.params.slug);
	}


	async load(slug) {
		if (this.state.userid != 0) {
		 await	axios.get(`${API_ROOT}webgetinvoice/${this.state.userid}/${slug}`).then(response => {
				// console.log(response.data.productdetsails);
				this.setState({ orderid: response.data.invoicedetails.orderid, orderdate: response.data.invoicedetails.created_at,  firstname:response.data.invoicedetails.firstname, lastname:response.data.invoicedetails.lastname, mobile:response.data.invoicedetails.mobile, address:response.data.invoicedetails.address, pincode:response.data.invoicedetails.pincode, productdetails: response.data.productdetsails })

				let qty=0;
				let price=0;
				let amount=0;
				for(var i=0; i< response.data.productdetsails.length; i++){
					qty = qty+ parseInt(this.state.productdetails[i].qty);
					price = price+ parseInt(this.state.productdetails[i].price);
					amount = amount+ (parseInt(this.state.productdetails[i].qty)*parseInt(this.state.productdetails[i].price))
				}	

				this.setState({totalamount: amount, totalprice:price, totalqty:qty})

			}).catch(error => {
				console.log(error);
			});
		}
	}

	render() {
		return (
			<div className="Invoice">
				<div className="invoice-content">
					<div className="invoice-header">
						<div className="left-invoice-box">
							<img src="/images/logo.png" alt="lalas-logo" />
						</div>
						<div className="right-invoice-box">
							<div>
								<h4>Invoice No.</h4>
								<p>{this.state.orderid}</p>
							</div>
							<div>
								<h4>dated</h4>
								<p> {moment(this.state.orderdate).format("MMM Do YY")}</p>
								
							</div>
						</div>
					</div>
					<div className="address-content">
						<div className="left-address-box">
							<h5>from</h5>
							<div className="inside-left-box">

								<div>
									<h6>lalas saree</h6>
									<p className="add">Address</p>
									<span>123 new design str, Abc building</span>
									<span>melbourne , <strong>indirapuram</strong></span>
									<p>email : abc@gmail.com</p>
								</div>
							</div>
						</div>
						<div className="left-address-box">
							<h5>to</h5>
							<div className="inside-left-box">
								<div>
									<h6>{this.state.firstname} {this.state.lastname}</h6>
									<p className="add">mobile - {this.state.mobile}</p>
									<span>Address - {this.state.address}</span>
									<p>pincode - {this.state.pincode}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="price-content">
						<table>
							<tr className="price-table">
								<th>sn.</th>
								<th>description of goods</th>
								<th>quantity</th>
								<th>price</th>
								<th>amount</th>
							</tr>
							{this.state.productdetails.length>0 && this.state.productdetails.map((object, i) => {
						return (	<tr className="price-amount" key={i}>
								<td>{i+1}</td>
							<td>{object.productname}</td>
							<td>{object.qty}</td>
								<td>{object.price}</td>
								<td>{object.price * object.qty }</td>
							</tr>
						)
							})
						}
							<tr className="price-amount">
								<td></td>
								<td>TOTAL (inclusive of all taxes)</td>
					<td>{this.state.totalqty}</td>
					<td>{this.state.totalprice}</td>
								<td><b>{this.state.totalamount}</b></td>
							</tr>
						</table>
					</div>
					<div className="decalration">
						<h5>declaration :</h5>
						<p>we declare that this invoice shows the actual price of the goods described and that all particular are true and correct</p>
					</div>
				</div>
			</div>

		)
	}
}
export default Invoice;